@mixin flexCentering($direction){
  display: flex;
  flex-direction: $direction;
  align-items: center;
  justify-content: center;
}

@mixin flexRow {
  display: flex;
  flex-direction: row;
}

@mixin flexColumn {
  display: flex;
  flex-direction: column;
}