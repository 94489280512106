/* Navbar */
.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: sticky;
  z-index: 10;
  padding: 7px 15px;
  flex-wrap: wrap;
  border-bottom: #E8E8E8 solid;
}
.navContainer .logoContainer {
  padding: 0 1rem 0 1rem;
}
.navContainer .logoContainer .logoImg {
  height: 1.5rem;
}
.navContainer .navlinkContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navContainer .navlinkContainer .linkContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  width: 100%;
  /* User drop down */
  /* End of user dropdown */
}
.navContainer .navlinkContainer .linkContainer li {
  list-style: none;
  font-size: 1.2rem;
  font-family: "PT Sans", sans-serif;
  cursor: pointer;
}
.navContainer .navlinkContainer .linkContainer li .createStore,
.navContainer .navlinkContainer .linkContainer li .SignIn,
.navContainer .navlinkContainer .linkContainer li .navbarMessages {
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
  transition: transform 0.4s ease;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}
.navContainer .navlinkContainer .linkContainer li .createStore:hover {
  border-bottom: 3px solid #cd4f28;
}
.navContainer .navlinkContainer .linkContainer li button {
  border: none;
  background: none;
}
.navContainer .navlinkContainer .linkContainer li .customerNavIcon {
  font-size: 1.5rem;
  color: #e5704c;
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.navContainer .navlinkContainer .linkContainer li .businessNavIcon {
  font-size: 1.5rem;
  color: #383966;
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.navContainer .navlinkContainer .linkContainer .dropdownBtn {
  position: relative;
  display: inline-block;
}
.navContainer .navlinkContainer .linkContainer .dropdownBtn .dropdownMenu {
  display: none;
  border-radius: 8px;
  position: absolute;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  padding: 1rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 20;
}
.navContainer .navlinkContainer .linkContainer .dropdownBtn .dropdownMenu .dropdownMenuItems {
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0;
}
.navContainer .navlinkContainer .linkContainer .dropdownBtn .dropdownMenu .dropdownMenuItems:hover {
  color: #e5704c;
}
.navContainer .navlinkContainer .linkContainer .dropdownBtn .dropdownMenu .dropdownMenuItems:hover .dropdownMenu {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translate(-50px, 0);
}
.navContainer .navlinkContainer .linkContainer .dropdownBtn:hover .dropdownMenu {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translate(-50px, 0);
}
.navContainer .hamburgerContainer {
  grid-column: 1/2;
  grid-row: 2/3;
}
.navContainer .searchbarContainer {
  display: flex;
  align-items: center;
  max-width: 70%;
  min-width: 50%;
}
@media screen and (max-width: 480px) {
  .navContainer .searchbarContainer {
    max-width: none;
    width: calc(100vw - 10px);
  }
}
.navContainer .searchbarContainer .headerSearchContainer {
  display: flex;
  width: 100%;
  height: 80%;
  align-items: center;
  border: #E8E8E8 solid;
  border-radius: 40px;
}
.navContainer .searchbarContainer .headerSearchContainer input[type=text] {
  height: 100%;
  padding: 10px 15px;
  width: 100%;
  outline: none;
  background-color: white;
  border: none;
  border-radius: 40px;
}
.navContainer .searchbarContainer .headerSearchContainer button[type=submit] {
  height: 38.5px;
  padding: 10px 15px;
  background-color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
}

@media (max-width: 480px) {
  .navContainer .logoContainer {
    width: 50%;
  }
  .navContainer .navlinkContainer {
    width: 50%;
    order: 1;
  }
  .navContainer .searchbarContainer {
    order: 2;
    padding: 0;
  }
}
.footerContainer {
  z-index: 100;
}
.footerContainer .footerContainerWrap {
  background-color: #F3F0ED;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
}
.footerContainer .footerContainerWrap .footerHeading {
  display: flex;
  gap: 15px;
}
@media screen and (max-width: 480px) {
  .footerContainer .footerContainerWrap .footerHeading {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
.footerContainer .footerContainerWrap .footerHeading h2 {
  font-size: 1.5rem;
  margin: 0;
}
@media screen and (max-width: 480px) {
  .footerContainer .footerContainerWrap .footerHeading h2 {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 480px) {
  .footerContainer .footerContainerWrap {
    flex-direction: column;
    gap: 10px;
  }
}
.footerContainer .socialMediaWrap .socialIcons {
  display: flex;
  gap: 15px;
}
.footerContainer .socialMediaWrap .socialIcons .socialIconLink {
  color: #979797;
  font-size: 24px;
}
@media screen and (max-width: 900px) {
  .footerContainer .socialMediaWrap .socialIcons .socialIconLink {
    font-size: 1rem;
  }
}
@media screen and (max-width: 480px) {
  .footerContainer {
    flex-direction: column;
    align-items: center;
  }
}

.footerRights {
  background: white;
  border-top: #E8E8E8 solid;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  padding: 15px;
}
@media screen and (max-width: 480px) {
  .footerRights {
    flex-direction: column;
  }
  .footerRights p, .footerRights a {
    font-size: 0.8rem;
  }
}
.footerRights .websiteRights {
  display: flex;
  align-items: center;
  padding: 15px;
}
.footerRights .websiteRights p {
  color: black;
}

.footerLink {
  text-decoration: none;
  font-size: 0.9rem;
  color: black;
  font-weight: lighter;
  cursor: pointer;
  padding: 0 15px;
}
@media screen and (max-width: 480px) {
  .footerLink {
    font-size: 0.8rem;
  }
}/*# sourceMappingURL=Global.css.map */