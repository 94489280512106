.noPendingContainer {
  background-color: #fafafa;
  height: 30vh;
  display: flex;
  justify-content: center;
}
.noPendingContainer h2 {
  color: salmon;
  text-align: center;
  line-height: 10vh;
}

.mySubscriptionsContainer {
  min-height: 90vh;
}
.mySubscriptionsContainer .typeSelector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.mySubscriptionsContainer .typeSelector p {
  font-size: 24px;
  margin: 0 20px;
  padding: 5px 8px;
}
.mySubscriptionsContainer .typeSelector p:hover {
  cursor: pointer;
}
.mySubscriptionsContainer .pendingContainer {
  display: grid;
  grid-template-columns: 1fr 25%;
}
.mySubscriptionsContainer .pendingContainer .pendingItemContainer {
  background-color: #fafafa;
  grid-column: 1/2;
}
.mySubscriptionsContainer .pendingContainer .checkoutContainer {
  background-color: white;
  grid-column: 2/3;
  height: -moz-fit-content;
  height: fit-content;
  width: 95%;
  margin: 10px auto;
}
.mySubscriptionsContainer .subscribedContainer {
  margin-top: 30px;
}
.mySubscriptionsContainer .subscribedContainer .sortBar {
  width: 100%;
  height: 20%;
  background-color: #fafafa;
  display: grid;
  grid-template-columns: 30% 70%;
  margin-bottom: 20px;
}
.mySubscriptionsContainer .subscribedContainer .sortBar .searchbarSide {
  grid-column: 1/2;
}
.mySubscriptionsContainer .subscribedContainer .sortBar .radioBtnSideContainer {
  grid-column: 2/3;
  width: 70%;
  height: 100%;
  position: relative;
  text-align: center;
}
.mySubscriptionsContainer .subscribedContainer .sortBar .radioBtnSideContainer p {
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.mySubscriptionsContainer .subscribedContainer .subscribedInfo {
  position: relative;
  width: 100%;
  display: grid;
  grid-auto-columns: 50% 50%;
}
.mySubscriptionsContainer .subscribedContainer .subscribedInfo .subscribedItemsDisplayContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}
.checkoutContentContainer {
  padding: 10px 20px;
}

.pmtSuccessModalBackground {
  width: 100%;
  height: 100vh;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pmtSuccessModalBackground .pmtSuccessModalWrapper {
  border-radius: 15px;
  background: #fff;
}
.pmtSuccessModalBackground .pmtSuccessModalWrapper .pmtSuccessModalContent {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #fa8072;
  font-size: 1.6rem;
  text-align: center;
}
.pmtSuccessModalBackground .pmtSuccessModalWrapper .pmtSuccessModalIcon {
  font-size: 3rem;
}

.coverLetterContainer {
  padding: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mySubscriptionsContainer {
  width: 100%;
}
.mySubscriptionsContainer .MSsubscriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mySubscriptionsContainer .MSsubscriptionContainer .subCard {
  width: 60%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  margin: 10px auto;
  padding-bottom: 10px;
}
@media screen and (max-width: 959px) {
  .mySubscriptionsContainer .MSsubscriptionContainer .subCard {
    display: flex;
    flex-direction: column;
  }
}
.mySubscriptionsContainer .MSsubscriptionContainer .subCard .itemCardSide {
  grid-column: 1/2;
  min-width: 290px;
  max-width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 959px) {
  .mySubscriptionsContainer .MSsubscriptionContainer .subCard .itemCardSide {
    margin: 0 auto;
  }
}
.mySubscriptionsContainer .MSsubscriptionContainer .subCard .itemInfo {
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 959px) {
  .mySubscriptionsContainer .MSsubscriptionContainer .subCard .itemInfo {
    padding-bottom: 10%;
    border-bottom: #E8E8E8 3px solid;
  }
}
.mySubscriptionsContainer .MSsubscriptionContainer .subCard .itemInfo .IItopInfo {
  border-bottom: #E8E8E8 solid;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.mySubscriptionsContainer .MSsubscriptionContainer .subCard .itemInfo .IItopInfo .data {
  font-size: 20px;
  padding: 20px;
}
.mySubscriptionsContainer .MSsubscriptionContainer .subCard .itemInfo .IIbottomInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.mySubscriptionsContainer .MSsubscriptionContainer .subCard .itemInfo .IIbottomInfo .dataLabel {
  color: #979797;
  text-align: left;
  padding: 8px 15px 8px 15px;
}
.mySubscriptionsContainer .MSsubscriptionContainer .subCard .itemInfo .IIbottomInfo .data {
  font-size: 20px;
  text-align: left;
  padding: 8px 25px 8px 25px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: auto;
}
.buttons button {
  height: 35px;
  width: 90px;
  margin: 15px;
  padding: 3px 5px 3px 5px;
  border: none;
  border-radius: 7px;
  color: white;
}
.buttons button:hover {
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.confirmContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 11;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  padding: 30px 50px;
  border-radius: 20px;
}
.confirmContainer p :last-child {
  font-size: 16px;
}

.mySubscriptionSkelton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=MySubscriptions.css.map */