@import "../../../../style/variable";

.gridContainer{
  display: flex;

  .sortBarContainer{
    padding-right: 30px;
    width: 100%;
    min-height: 80vh;
    flex: 0 0 145px;
    @media screen and (max-width: 480px) {
      display: none;
    }

    .priceRange{
      width: 100%;

      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;

      label{
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        justify-content: space-between;

        input[type=radio]{
        width: 30px;
        margin-right: 3px;
        transform: scale(0.5);
        }
      }

    }
  }

  .itemContainer{
    margin-top: 10px;
    flex: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 20px;
    @media screen and (max-width: 480px) {
      justify-content: center;
    }


    .l-searchItemCard{
      width: 300px;
      height: 400px;

      a{
        text-decoration: none;

        p{
          color: $letterColorDark;
        }
      

      img{
        object-fit: cover;
        width: 100%;
        height: 65%;
        border-radius: 32px;

      }

      

      .p-searchItemCardInfo{
        height: 35%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        div{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .nameNAvatar{
          margin-top: 5px;
          display: flex;
          justify-content: flex-start;

          img {
            width: 35px;
            height: 35px;

          }
    
          #businessName{
            margin-left: 15px;
            font-style: italic;
            word-wrap:normal;
            font-size: $normalFontSize;
            font-weight: normal;
          }

          #businessLocation {
            font-size: $normalFontSize;
            font-weight: normal;
            color: $neutralColor;
            margin-left: auto;
          }
        }

        .l-labels{

          label{
            color: $letterColor;
            background-color: $mainColor;
            border-radius: 6px;
            padding: 2.5px 5px;
          }
        }

        

        
        .c-itemName{
          color: $letterColorDark;
          font-size: $appealFontSize - 2;
          font-weight: 700;
          
        }

        .c-itemPrice{
          font-size: $appealFontSize;
          font-weight: bold;
          margin: 5px 0;
        }

        .c-itemSubTypes{
          font-size: $normalFontSize;
          color: $neutralColor;
        }

      

      }
    
    }
  }
  }

}

.itemNotFoundContainer {
  width: 100%;
  height: calc(100vh - 65px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: $contentColor;

  @media screen and (max-width: 480px) {
    height: 70svh;
    margin-left: -20px;
  }

  &__subtitle {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 18px;

    span {
      font-size: 24px;
    }

    strong {
      font-size: 24px;
    }
  }

  // p{
  //   text-align: center;
  //   line-height: 2.8;
  //   font-size: $titleFontSize;

  //   @media screen and (max-width: 480px) {
  //     line-height: 1.8;
  //     font-size: $appealFontSize;
  //   }
  // }

  &__keyword {
    text-decoration: underline;
    font-weight: bold;
  }
}