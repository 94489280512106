// Color variables

//Customer Side
$mainColor: #e5704c;
$mainColorLight: #e5704c49;
$thirdColor: #ff5555cc;
$secondColor: #b64027;
$subColor: salmon;
$lightColor: rgba(229, 112, 76, 0.1);
$mediumLightColor: rgba(229, 112, 76, 0.15);

$newOrange: #ff642d;

// New Customer side Color 
$primary:#E96232;
$secondary:#EE8853;
$tertiary: #3C6124;
$tertiary-light:#CFFC4F;

// Bus Primary
$busprimary-900: #810000;
$busprimary-800: #9F0000;
$busprimary-700: #BC1500;
$busprimary-600: #D8300D;
$busprimary-500: #F34923;
$busprimary-400: #FF6238;
$busprimary-300: #FF7B4F;
$busprimary-200: #FF9569;
$busprimary-100: #FFB084;
$busprimary-50:  #FFCCA2;

// Bus Secondary
$bussecondary-900: #711900;
$bussecondary-800: #8C2800;
$bussecondary-700: #A73A00;
$bussecondary-600: #C24D00;
$bussecondary-500: #DB6217;
$bussecondary-400: #F47830;
$bussecondary-300: #FF8F48;
$bussecondary-200: #FFA663;
$bussecondary-100: #FFBF7F;
$bussecondary-50:  #FFD99D;

// Cust Primary
$custprimary-900: #244011;
$custprimary-800: #33541E;
$custprimary-700: #45682D;
$custprimary-600: #587C3E;
$custprimary-500: #6C9151;
$custprimary-400: #81A666;
$custprimary-300: #97BB7C;
$custprimary-200: #AED193;
$custprimary-100: #C6E7AC;
$custprimary-50:  #DFFDC7;

// Cust Secondary
$custsecondary-900: #204200;
$custsecondary-800: #2B5600;
$custsecondary-700: #3B6B00;
$custsecondary-600: #4F7F00;
$custsecondary-500: #659400;
$custsecondary-400: #7DAA00;
$custsecondary-300: #95BF18;
$custsecondary-200: #AED440;
$custsecondary-100: #C8EA62;
$custsecondary-50:  #E2FF84;


// White Shades
$white-900: #393939;
$white-800: #4B4B4B;
$white-700: #5E5E5E;
$white-600: #727272;
$white-500: #868686;
$white-400: #9B9B9B;
$white-300: #B0B0B0;
$white-200: #C6C6C6;
$white-100: #DDDDDD;
$white-50:  #F3F3F3;
$white-00:  #FFFFFF;

//item type
$serviceColor: #407b1b;
$productColor: #ca0303;
$membershipColor: #063ca6;

$serviceColorLight: #e5eee8;
$productColorLight: #dd04041a;
$membershipColorLight: #0250e81a;

//Business Side
$businessMainColor: #383966;
$businessSubColor: #7f7cbe;
$businessSubLinkColor: #9da0e8;
$businessThirdColor: #888ad51a;
$businessThirdColorSolid: #888ad5;
$businessBackgroundColor: #ededf1;

$businessActivityPurchased: #5adca8;
$businessActivityRenewed: #a761d5;
$rechartsTooltipBGcolor: #378b6a;

//Neutral Letter Color
$letterColor: white;
$letterColorDark: black;
$neutralColor: #979797;
$lightNeutral: #dfdfdf;
$darkNeutral: #6b6b6b;
$hoverColorWhite: rgba(
  $color: #000000,
  $alpha: 0.1,
);

// Border color
$borderLightGray: #e7e4e2;

// Content Color
$contentColor: white;
$borderColor: #e8e8e8;
$backgroundColor: #fafafa;
$backgroundColor2: #f8f8f8;
$backgroundColor3: rgba(229, 112, 76, 0.1);
$grayColor: #2a393e;
$backgroundGrayColor: #f3f0ed;
$whiteHoverColor: #dad8d6;

// Admin Page styling
$adminBackgroundColor: #f5f5f5;
$resolvedColor: #45925e;
$resolvedColorLight: #97e2af;

$pausedColor: #5530ea;
$pausedColorLight: #c2b3ff;

$removedColor: #a62827;
$removedColorLight: #ff828279;

// Analytics page styling
$increasedColor: #25d390;
$decreasedColor: #e25656;
$analyticsBoxColor: #7d54f3;
$analyticsBoxFontColorOne: #e7e1e1;
$analyticsBoxFontColorTwo: #585656;

// Gradients
$businessButtonGradient: linear-gradient(to right bottom, #edd5e3, #d784c0, #d081c9, #c57ed3, #b77ddd, #aa74df, #9c6ce1, #8b65e3, #7d53dd, #6f40d6, #612bcf, #510dc8);