.TAContainer {
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  grid-template-columns: auto auto;
  margin: 60px auto;
  min-height: 70vh;
}
@media screen and (max-width: 959px) {
  .TAContainer {
    display: flex;
    flex-direction: column;
  }
}
.TAContainer .TAServiceDisplay {
  grid-column: 1/2;
  display: flex;
  flex-direction: column;
  border-right: solid #E8E8E8;
  padding-right: 20%;
}
@media screen and (max-width: 959px) {
  .TAContainer .TAServiceDisplay {
    padding-right: 0%;
    padding-bottom: 10%;
  }
}
.TAContainer .TAServiceDisplay h2 {
  font-weight: 400;
  font-size: 23px;
  text-align: center;
}
.TAContainer .TAServiceDisplay .TAServiceInfoCard {
  border: solid #313131;
  border-radius: 18px;
  margin: 0 auto;
  width: 345px;
  height: 120px;
  overflow: hidden;
}
.TAContainer .TAServiceDisplay .TAServiceInfoCard p {
  width: 100%;
  margin: 20px 0px 0px 27px;
}
.TAContainer .TAcalendarDisplay {
  grid-column: 2/3;
  width: 380px;
  display: flex;
  flex-direction: column;
}
.TAContainer .TAcalendarDisplay .TATimeDisplay {
  border-top: solid #e8e8e8;
}
.TAContainer .TAcalendarDisplay .TATimeDisplay .TAselectedDate {
  margin: 14px 0px 0px 35px;
  font-size: 23px;
  font-weight: 400;
}
.TAContainer .TAcalendarDisplay .TATimeDisplay .spotsDisplay p {
  font-size: large;
  margin: 15%;
}
.TAContainer .TAcalendarDisplay .TATimeDisplay .spotsDisplay button {
  background-color: #D05028;
  color: white;
  border-radius: 10px;
  width: 99px;
  height: 37px;
  border: none;
  margin: 20px 12px 5px 12px;
}
.TAContainer .TAcalendarDisplay .TATimeDisplay .spotsDisplay .selectedButton {
  background-color: skyblue;
  color: white;
  border-radius: 10px;
  width: 99px;
  height: 37px;
  border: none;
  margin: 5px 12px 5px 12px;
}
.TAContainer .TAcalendarDisplay .disabledMessage {
  background-color: #D05028;
  color: white;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
}
.TAContainer .TAcalendarDisplay button {
  margin-top: 20%;
  margin-left: auto;
  background-color: #D05028;
  color: white;
  border-radius: 10px;
  width: 200px;
  height: 37px;
  border: none;
}
.TAContainer .TAcalendarDisplay button:disabled {
  background-color: gray;
}/*# sourceMappingURL=TimeAvailability.css.map */