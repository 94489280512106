.gridContainer {
  display: flex;
}
.gridContainer .sortBarContainer {
  padding-right: 30px;
  width: 100%;
  min-height: 80vh;
  flex: 0 0 145px;
}
@media screen and (max-width: 480px) {
  .gridContainer .sortBarContainer {
    display: none;
  }
}
.gridContainer .sortBarContainer .priceRange {
  width: 100%;
}
.gridContainer .sortBarContainer .priceRange label {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  justify-content: space-between;
}
.gridContainer .sortBarContainer .priceRange label input[type=radio] {
  width: 30px;
  margin-right: 3px;
  transform: scale(0.5);
}
.gridContainer .itemContainer {
  margin-top: 10px;
  flex: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px;
}
@media screen and (max-width: 480px) {
  .gridContainer .itemContainer {
    justify-content: center;
  }
}
.gridContainer .itemContainer .l-searchItemCard {
  width: 300px;
  height: 400px;
}
.gridContainer .itemContainer .l-searchItemCard a {
  text-decoration: none;
}
.gridContainer .itemContainer .l-searchItemCard a p {
  color: black;
}
.gridContainer .itemContainer .l-searchItemCard a img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 65%;
  border-radius: 32px;
}
.gridContainer .itemContainer .l-searchItemCard a .p-searchItemCardInfo {
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.gridContainer .itemContainer .l-searchItemCard a .p-searchItemCardInfo div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.gridContainer .itemContainer .l-searchItemCard a .p-searchItemCardInfo .nameNAvatar {
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
}
.gridContainer .itemContainer .l-searchItemCard a .p-searchItemCardInfo .nameNAvatar img {
  width: 35px;
  height: 35px;
}
.gridContainer .itemContainer .l-searchItemCard a .p-searchItemCardInfo .nameNAvatar #businessName {
  margin-left: 15px;
  font-style: italic;
  word-wrap: normal;
  font-size: 16px;
  font-weight: normal;
}
.gridContainer .itemContainer .l-searchItemCard a .p-searchItemCardInfo .nameNAvatar #businessLocation {
  font-size: 16px;
  font-weight: normal;
  color: #979797;
  margin-left: auto;
}
.gridContainer .itemContainer .l-searchItemCard a .p-searchItemCardInfo .l-labels label {
  color: white;
  background-color: #e5704c;
  border-radius: 6px;
  padding: 2.5px 5px;
}
.gridContainer .itemContainer .l-searchItemCard a .p-searchItemCardInfo .c-itemName {
  color: black;
  font-size: 22px;
  font-weight: 700;
}
.gridContainer .itemContainer .l-searchItemCard a .p-searchItemCardInfo .c-itemPrice {
  font-size: 24px;
  font-weight: bold;
  margin: 5px 0;
}
.gridContainer .itemContainer .l-searchItemCard a .p-searchItemCardInfo .c-itemSubTypes {
  font-size: 16px;
  color: #979797;
}

.itemNotFoundContainer {
  width: 100%;
  height: calc(100vh - 65px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
}
@media screen and (max-width: 480px) {
  .itemNotFoundContainer {
    height: 70svh;
    margin-left: -20px;
  }
}
.itemNotFoundContainer__subtitle {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 18px;
}
.itemNotFoundContainer__subtitle span {
  font-size: 24px;
}
.itemNotFoundContainer__subtitle strong {
  font-size: 24px;
}
.itemNotFoundContainer__keyword {
  text-decoration: underline;
  font-weight: bold;
}/*# sourceMappingURL=SearchResult.css.map */