@use "../../Foundation/Global" as *;


.c-buttons{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.c-Btn {
  height: fit-content;
  margin: 10px 10px;
  display: block;
  background-color: $mainColor;
  padding:10px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  border: none;
  &--gray {
    @extend .c-Btn;
    background-color: $neutralColor; 
  }

  &--white {
    @extend .c-Btn;
    background-color: $contentColor; 
    color: $letterColorDark;
  }

  &--black {
    @extend .c-Btn;
    background-color: black; 
    color: $letterColor;
  }

  &--content{
    @extend .c-Btn;
    width: fit-content;
  }

  &--business{
    @extend .c-Btn;
    background-color: $businessMainColor; 
    width: fit-content; 
  }
}

.c-smallButtons{
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;

  @include mq("sp"){
    gap: 12px;
  }
  @include mq("tab"){
    gap: 12px
  }

  &--center{
    @include flexCentering(column);
  }

  &--end{
    margin: 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }

}

.c-smallButton{
  padding: 6px 16px ;
  border-radius: 12px;
  border: none;
  background-color: $mainColor;
  color: white;
  font-weight: 700;
  margin-right: 16px;
  cursor: pointer;
  font-size: 16px;
  transition: .2s ease-in-out;

  &:hover{
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  @include mq("sp"){
    font-size: 14px;
    margin-right: 8px;
    padding: 6px 16px;
  }
  @include mq("tab"){
    font-size: 14px;
    margin-right: 8px;
    padding: 4px 16px;
  }

  &--white{
    @extend .c-smallButton;
    color: $mainColor;
    background-color: white;
    border: $mainColor solid;
  }

  &--whiteBlack{
    @extend .c-smallButton;
    color: black;
    background-color: white;
    border: black solid;
  }

  &--black{
    @extend .c-smallButton;
    color: white;
    background-color: black;
    border: black solid;
  }

  &--business{
    @extend .c-smallButton;
    color: white;
    background-color: $businessThirdColorSolid;
    border: $businessThirdColorSolid solid;
  }

  &--businessWhite{
    @extend .c-smallButton;
    color: $businessThirdColorSolid;
    background-color: white;
    border: $businessThirdColorSolid solid;
  }

  &--gray{
    @extend .c-smallButton;
    color: #313131;
    background-color: #E8E8E8;
    border: none;
  }
}

.u-Btn {
  min-width: 60px;
  width: fit-content;
  height: fit-content;
  margin: 10px 10px;
  display: block;
  background-color: $mainColor;
  padding:10px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  border: none;

  &--orange {
    @extend .u-Btn;
    background-color: $mainColor;

    &:hover {
      background-color: lighten($mainColor, 8%) !important;
    } 
  }

  &--gray {
    @extend .u-Btn;
    background-color: $neutralColor; 
  }

  &--white {
    @extend .u-Btn;
    background-color: $contentColor; 
    color: $letterColorDark;
  }

  &--black {
    @extend .u-Btn;
    background-color: black; 
    color: $letterColor;
  }

  &--content{
    @extend .u-Btn;
    width: fit-content;
  }

  &--business{
    @extend .u-Btn;
    background-color: $businessMainColor; 
    width: fit-content; 
  }

  &--sort{
    margin-left: 90%;
  }
}

.c-btnRipple::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(circle, #fff 10%, transparent 10%) no-repeat 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.3s, opacity 1s;
}