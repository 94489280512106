/* Account info */
.authModal__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
}

/* Form */
.authModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  width: 90%;
  max-width: 500px;
  background-color: white;
  z-index: 20;
  border-radius: 1rem;
}
@media screen and (min-width: 700px) {
  .authModal {
    width: 55%;
  }
}
.authModal .authModal__header .authModal__left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.authModal .authModal__header .authModal__left h2 {
  color: #e5704c;
  margin: 0;
  text-transform: uppercase;
}
.authModal .authModal__header .authModal__left button {
  background-color: #e5704c;
  border: 0;
  padding: 0.5rem;
  color: white;
  border-radius: 1rem;
  cursor: pointer;
  margin-right: 2rem;
}
.authModal .authModal__header .authModal__right {
  position: absolute;
  top: 5%;
  right: 5%;
}
.authModal .authModal__header .authModal__right button {
  border: 0;
  border-radius: 1rem;
  background-color: white;
  font-size: 1.5rem;
  cursor: pointer;
}
.authModal .authModal__header .authModal__right button:hover {
  background-color: #e5704c;
  color: white;
}
.authModal .authModal__errors {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
}
.authModal .authModal__errors .authModal__error {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: salmon;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  border: 2px solid red;
}
.authModal .authModal__errors .authModal__error p {
  display: inline;
  color: white;
}
.authModal .authModal__errors .authModal__error span {
  color: white;
  cursor: pointer;
}
.authModal form {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.authModal form label {
  display: block;
  margin: 5px 0;
}
.authModal form label input {
  display: block;
  width: 100%;
  margin: 5px 0;
}
.authModal form button {
  background-color: #e5704c;
  border: 0;
  padding: 0.5rem;
  color: white;
  border-radius: 1rem;
  width: 50%;
  margin: 0 auto;
  margin-top: 1rem;
  cursor: pointer;
}
.authModal .authModal__footer .authModal__footer__social {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.authModal .authModal__footer .authModal__footer__social button {
  background-color: #e5704c;
  border: 0;
  padding: 0.5rem;
  color: white;
  border-radius: 0.5rem;
  cursor: pointer;
  text-transform: capitalize;
  margin: 0 auto;
  margin-bottom: 1rem;
  width: 100%;
}
.authModal .authModal__footer a {
  color: #e5704c;
}

/* End of Form */
.BannerContainer {
  /* end of bannercontentdotswrapper*/
}
.BannerContainer .bannerContentDotsWrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* end of banner wrapper */
}
.BannerContainer .bannerContentDotsWrapper .bannerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 480px) {
  .BannerContainer .bannerContentDotsWrapper .bannerWrapper {
    position: relative;
  }
}
.BannerContainer .bannerContentDotsWrapper .bannerWrapper .bannerSlidesArrow {
  display: none;
  font-size: 2.5rem;
  color: #979797;
  cursor: pointer;
}
@media screen and (min-width: 480px) {
  .BannerContainer .bannerContentDotsWrapper .bannerWrapper .bannerSlidesArrow {
    display: flex;
  }
}
.BannerContainer .bannerContentDotsWrapper .bannerWrapper .bannerContentWrapper {
  border-radius: 10px;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  width: 100%;
}
.BannerContainer .bannerContentDotsWrapper .bannerWrapper .bannerContentWrapper:hover {
  cursor: pointer;
}
@media screen and (min-width: 480px) {
  .BannerContainer .bannerContentDotsWrapper .bannerWrapper .bannerContentWrapper {
    grid-template-rows: none;
    grid-template-columns: 40% 60%;
    max-height: 400px;
  }
}
.BannerContainer .bannerContentDotsWrapper .bannerWrapper .bannerContentWrapper .bannerImg {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  position: relative;
  height: 200px;
}
@media screen and (min-width: 480px) {
  .BannerContainer .bannerContentDotsWrapper .bannerWrapper .bannerContentWrapper .bannerImg {
    grid-row: 1/2;
    grid-column: 2/3;
  }
}
@media screen and (min-width: 769px) {
  .BannerContainer .bannerContentDotsWrapper .bannerWrapper .bannerContentWrapper .bannerImg {
    height: 300px;
  }
}
.BannerContainer .bannerContentDotsWrapper .bannerWrapper .bannerContentWrapper .bannerImg img {
  width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
@media screen and (min-width: 480px) {
  .BannerContainer .bannerContentDotsWrapper .bannerWrapper .bannerContentWrapper .bannerImg img {
    border-top-left-radius: 0px;
    border-bottom-right-radius: 10px;
  }
}
.BannerContainer .bannerContentDotsWrapper .bannerContent {
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #e5704c;
  padding: 1rem;
  padding-top: 0.6rem;
  text-align: center;
  background-color: #fafafa;
}
.BannerContainer .bannerContentDotsWrapper .bannerContent:hover {
  cursor: pointer;
}
@media screen and (min-width: 769px) {
  .BannerContainer .bannerContentDotsWrapper .bannerContent {
    grid-row: 1/2;
    grid-column: 1/2;
    justify-content: center;
    align-items: start;
    position: absolute;
    padding: 1.5rem;
    top: 50%;
    left: 8%;
    color: #e5704c;
    border-radius: 1rem;
    background-color: #e5704c;
  }
}
@media screen and (min-width: 1000px) {
  .BannerContainer .bannerContentDotsWrapper .bannerContent {
    left: 5%;
    color: #e5704c;
    background-color: #e5704c;
  }
}
.BannerContainer .bannerContentDotsWrapper .bannerContent h1 {
  font-style: italic;
  font-weight: 700;
  font-size: 1.8rem;
  margin: 0;
  color: #e5704c;
}
@media screen and (min-width: 769px) {
  .BannerContainer .bannerContentDotsWrapper .bannerContent h1 {
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: white;
  }
}
@media screen and (min-width: 1000px) {
  .BannerContainer .bannerContentDotsWrapper .bannerContent h1 {
    font-size: 3rem;
    margin-bottom: 0.5rem;
    color: white;
  }
}
.BannerContainer .bannerContentDotsWrapper .bannerContent p {
  font-weight: 400;
  font-size: 1rem;
}
@media screen and (min-width: 1000px) {
  .BannerContainer .bannerContentDotsWrapper .bannerContent p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: white;
  }
}
@media screen and (min-width: 769px) {
  .BannerContainer .bannerContentDotsWrapper .bannerContent p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: white;
  }
}
.BannerContainer .bannerContentDotsWrapper .bannerContent button {
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  padding: 12px;
  border-radius: 15px;
  font-size: 0.9rem;
  border: none;
  background-color: white;
  color: rgb(138, 138, 138);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.BannerContainer .bannerContentDotsWrapper .bannerContent button:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.05, 1.05);
  opacity: 0.9;
}
@media screen and (min-width: 480px) {
  .BannerContainer .bannerContentDotsWrapper .bannerContent button {
    display: flex;
  }
}
.BannerContainer .bannerContentDotsWrapper .bannerContent button .bannerButtonArrow {
  font-size: 1.4rem;
}
@media (max-width: 768px) {
  .BannerContainer .bannerContentDotsWrapper .bannerContent button .bannerButtonArrow {
    font-size: 1rem;
  }
}
.BannerContainer .bannerContentDotsWrapper .bannerSlidesDotsWrapper {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.BannerContainer .bannerContentDotsWrapper .bannerSlidesDotsWrapper .bannerSlidesDots {
  height: 12px;
  width: 12px;
  margin: 1rem;
  background-color: #D9D9D9;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}
.BannerContainer .bannerContentDotsWrapper .bannerSlidesDotsWrapper .bannerSlidesDots:hover {
  background-color: #979797;
}

.feedbackContainer {
  width: 95%;
  margin: 20px auto;
  background-color: salmon;
}
.feedbackContainer form {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 5px auto;
}

/* Global Nav */
.navSlide.close {
  display: none;
}

.navSlide.open {
  display: flex;
}

.globalNavContainer {
  position: absolute;
  background-color: white;
  padding: 8px;
  display: flex;
  justify-content: space-evenly;
  display: none;
  flex-direction: row;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0 4px 2px -2px rgba(128, 128, 128, 0.257);
}
@media screen and (min-width: 480px) {
  .globalNavContainer {
    display: flex;
    font-size: 0.6rem;
    text-align: center;
  }
}
@media screen and (min-width: 1000px) {
  .globalNavContainer {
    font-size: 0.9rem;
  }
}

.globalNavContainer > button {
  outline: none;
  font-size: 15px;
  margin: 2px 10px;
  background: transparent;
  border: none;
  padding: 1px 5px;
}

.globalNavContainer > button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.globalMenu {
  position: relative;
}

.globalMenu button {
  position: fixed;
  left: 40px;
  top: 40px;
  z-index: 10;
  cursor: pointer;
}

.menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  background: darkcyan;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  max-width: 290px;
  z-index: 9;
}

.menuNav.showMenu {
  width: 100%;
}

.menuNav li:first-child {
  margin-top: 7rem;
}

.globalNavContainer > * {
  color: black;
  text-decoration: none;
  font-family: "PT Sans", sans-serif;
  margin: 0px 10px;
}

/* Main Product Cards */
/* Normal shadow 2 2 2 => hover 5 5 5 */
.mainHero {
  min-height: 70vh;
  margin: 60px 0px;
}
.mainHero .productLine:hover .arrows {
  color: #2A393E;
}
.mainHero .productLine > h2, .mainHero #TrendingNow, .mainHero #Recent {
  margin: 50px 15px;
  background-color: #fafafa;
  color: #D05028;
  font-size: 1.6rem;
  font-weight: bold;
  padding-left: 50px;
}
.mainHero .productLine .scrollProduct {
  display: grid;
  grid-template-columns: 5% 90% 5%;
  align-items: center;
}
.mainHero .arrows {
  font-size: 3rem;
  padding: 10px;
  cursor: pointer;
  justify-self: center;
  color: #FAFAFA;
}

.itemCards, #trendingProduct, #recentProduct {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  align-items: center;
  scroll-behavior: smooth;
  height: 100%;
  overflow-y: hidden;
}

.scrollProduct::-webkit-scrollbar-thumb {
  background-color: #FAFAFA !important;
}

.scrollProduct:hover::-webkit-scrollbar-thumb {
  background-color: rgb(151, 151, 151) !important;
}

.scrollProduct::-webkit-scrollbar-track {
  background-color: #FAFAFA !important;
}

.itemCards::-webkit-scrollbar {
  overflow-x: hidden;
}

.itemCard {
  width: 320px;
  height: 100%;
  position: relative;
  border: 1px solid #E8E8E8;
}
.itemCard .cardInfo {
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 11px;
  text-decoration: none;
}
.itemCard .cardInfo .cardPrice {
  font-size: 1.2rem;
  color: white;
  font-weight: bold;
  position: absolute;
  background-color: #e5704c;
  padding: 5px 15px;
  border-radius: 5px;
  top: 115px;
  left: 10px;
  box-shadow: -2px 5px 5px 0px rgba(0, 0, 0, 0.4);
}
.itemCard .cardInfo .cardImage img {
  width: 100%;
  height: 180px;
  -o-object-fit: cover;
     object-fit: cover;
}
.itemCard .cardInfo .cardDetails {
  padding: 15px 15px 0 15px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 480px) {
  .itemCard .cardInfo .cardDetails {
    padding: 10px 10px 0 10px;
  }
}
.itemCard .cardInfo .cardDetails .cardItemName {
  display: block;
  font-weight: normal;
  color: #2A393E;
  font-size: clamp(1rem, 4vw, 1.3rem);
  margin: 0;
  text-align: left;
}
.itemCard .cardInfo .cardDetails .cardSubtype {
  font-size: 1.3rem;
  color: #df8367;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: 1px;
  font-size: clamp(0.6rem, 4vw, 1.1rem);
  text-align: left;
}
.itemCard .cardInfo .cardDetails .cardOwner {
  clear: both;
  font-size: clamp(0.6rem, 4vw, 1.1rem);
  color: #969696;
  margin: 0px;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: 1px;
}
.itemCard .cardInfo .cardDetails .ratingWrapper {
  display: flex;
  align-items: center;
}
.itemCard .cardInfo .cardDetails .ratingWrapper .itemRating {
  font-size: 18px;
  color: #df8367;
  vertical-align: middle;
  vertical-align: sub;
  text-decoration: none;
  margin: 0;
}
.itemCard .cardInfo .cardDetails .ratingWrapper .itemRatingNumber {
  margin-left: 10px;
  text-decoration: none;
  color: #df8367;
}

.itemCard > a {
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  height: 100%;
}

.itemCard:hover {
  box-shadow: 0px 5px 5px #e8e8e8;
}

.favorites {
  display: flex;
  background-color: #A0C4D2;
  height: 465px;
  align-items: center;
  padding: 30px;
}
.favorites .fav_container {
  max-width: 1500px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: 30px;
}
.favorites .fav_container .team_fav {
  background-color: #61828F;
  height: 200px;
  width: 100%;
  display: flex;
  border-radius: 26px;
  grid-column: 1/3;
  grid-row: 1/2;
  justify-content: flex-end;
}
.favorites .fav_container .team_fav .fav_desc {
  padding: 20px;
}
.favorites .fav_container .team_fav .fav_desc p {
  font-size: 0.7rem;
  color: white;
  padding: 0px;
}
.favorites .fav_container .team_fav .fav_desc h2 {
  color: white;
  font-size: 1.2rem;
}
@media (max-width: 1024px) {
  .favorites .fav_container .team_fav .fav_desc h2 {
    font-size: 1rem;
  }
}
.favorites .fav_container .team_fav .fav_desc button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 15px;
  width: 100px;
  border: none;
  font-size: 1rem;
  color: #969696;
  font-size: 0.9rem;
  border: none;
  background-color: white;
  color: rgb(138, 138, 138);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
  cursor: pointer;
}
@media (max-width: 1024px) {
  .favorites .fav_container .team_fav .fav_desc button {
    padding: 8px;
    width: 80px;
  }
}
.favorites .fav_container .team_fav .fav_desc button .favDescButtonArrow {
  font-size: 1.2rem;
}
.favorites .fav_container .team_fav img {
  width: 500px;
  border-radius: 0px 15px 15px 0px;
}
.favorites .fav_container img {
  height: 200px;
  border-radius: 26px;
  -o-object-fit: cover;
     object-fit: cover;
}
.favorites .fav_container .fav2 {
  grid-column: 3/4;
  grid-row: 1/2;
  height: 150px;
}
.favorites .fav_container .fav2 img {
  width: 100%;
}
.favorites .fav_container .fav3 {
  grid-row: 1/2;
  grid-column: 4/5;
}
.favorites .fav_container .fav3 img {
  width: 100%;
}
.favorites .fav_container .fav4 {
  grid-column: 1/2;
  grid-row: 2/3;
}
.favorites .fav_container .fav4 img {
  width: 100%;
}
.favorites .fav_container .fav5 {
  grid-column: 2/3;
  grid-row: 2/3;
}
.favorites .fav_container .fav5 img {
  width: 100%;
}
.favorites .fav_container .fav6 {
  grid-column: 3/4;
  grid-row: 2/3;
}
.favorites .fav_container .fav6 img {
  width: 100%;
}
.favorites .fav_container .fav7 {
  grid-column: 4/5;
  grid-row: 2/3;
}
.favorites .fav_container .fav7 p {
  color: #fff;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .itemCards {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-x: scroll;
    height: 540px;
  }
  .itemCard {
    width: 100%;
    min-width: 200px;
  }
  .itemCard > a > img {
    width: 240px;
    height: 150px;
    grid-row: 1/2;
    grid-column: none;
    margin: 4px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .cardInfo {
    width: 100%;
  }
  .cardImage img {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .cardLocation {
    margin: 0 2px;
    font-size: 0.8rem;
    width: 40%;
    height: 50%;
    text-align: center;
  }
  .cardPrice {
    margin: 0 2px;
    font-size: 1.4rem;
    text-align: center;
  }
  .cardCompanyName {
    display: none;
  }
  .cardSubtype {
    display: block;
    text-align: left;
  }
  .cardReview {
    display: none;
  }
}
/* Product Category */
.productCategoryContainer {
  width: 100%;
}
.productCategoryContainer .categorySectionWrapper .productCategoryImagesWrapper {
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
  padding: 1rem;
}
@media screen and (min-width: 480px) {
  .productCategoryContainer .categorySectionWrapper .productCategoryImagesWrapper {
    justify-content: center;
  }
}
.productCategoryContainer .categorySectionWrapper .productCategoryImagesWrapper .productCategoryImage {
  text-align: center;
  color: black;
  font-size: 0.9rem;
  cursor: pointer;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 426px) {
  .productCategoryContainer .categorySectionWrapper .productCategoryImagesWrapper .productCategoryImage {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.productCategoryContainer .categorySectionWrapper .productCategoryImagesWrapper .productCategoryImage:hover {
  transform: scale(1.1);
}
.productCategoryContainer .categorySectionWrapper .productCategoryImagesWrapper .productCategoryImage img {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (min-width: 426px) {
  .productCategoryContainer .categorySectionWrapper .productCategoryImagesWrapper .productCategoryImage img {
    height: 5rem;
    width: 5rem;
  }
}

/* End of Product Category Container */
.businessCardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 450px) {
  .businessCardContainer {
    gap: 45px;
  }
}
.businessCardContainer .businessCard {
  width: 30%;
  height: 46vh;
  border: #E8E8E8 solid;
}
@media screen and (max-width: 1000px) {
  .businessCardContainer .businessCard {
    width: 45%;
    height: 30vh;
  }
}
@media screen and (max-width: 480px) {
  .businessCardContainer .businessCard {
    width: 80%;
    height: 32vh;
  }
}
.businessCardContainer .businessCard a {
  text-decoration: none;
}
.businessCardContainer .businessCard a:hover {
  cursor: pointer;
}
.businessCardContainer .businessCard a .imageContainer {
  background-color: #2A393E;
}
.businessCardContainer .businessCard a .imageContainer img {
  width: 100%;
  height: 30vh;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 1000px) {
  .businessCardContainer .businessCard a .imageContainer img {
    height: 20vh;
  }
}
@media screen and (max-width: 480px) {
  .businessCardContainer .businessCard a .imageContainer img {
    height: 20vh;
  }
}
.businessCardContainer .businessCard a .informationContainer {
  color: black;
}
.businessCardContainer .businessCard a .informationContainer h1 {
  width: 100%;
  text-align: left;
  padding-left: 20px;
  margin: 2%;
  font-size: 22px;
  border-bottom: #E8E8E8 solid;
}
.businessCardContainer .businessCard a .informationContainer div {
  display: flex;
  flex-direction: row;
}
.businessCardContainer .businessCard a .informationContainer div p {
  width: 70%;
  text-align: left;
  font-size: 100%;
  padding: 3px;
  margin: 3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  line-height: 1.6;
  height: 4.8rem;
}
@media screen and (max-width: 1000px) {
  .businessCardContainer .businessCard a .informationContainer div p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    height: 3.2rem;
  }
}
@media screen and (max-width: 480px) {
  .businessCardContainer .businessCard a .informationContainer div p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    height: 1.6rem;
  }
}
.businessCardContainer .businessCard a .informationContainer div .tag {
  width: 30%;
  margin: 15px auto;
  font-size: 30px;
}
@media screen and (max-width: 480px) {
  .businessCardContainer .businessCard a .informationContainer div .tag {
    margin: 5px auto;
  }
}

.skeltonAlign {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.skeltonAlign svg {
  margin: 10px;
}/*# sourceMappingURL=HomePage.css.map */