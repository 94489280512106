@import '../../../../style/variable';
// Time Availability

.TAContainer {
  width: fit-content;
  display: grid;
  grid-template-columns: auto auto;
  margin: 60px auto;
  min-height: 70vh;

  @media screen and (max-width: 959px) {
    display: flex;
    flex-direction: column;
  }

  .TAServiceDisplay{
    grid-column: 1/2;
    display: flex;
    flex-direction: column;
    border-right: solid #E8E8E8;
    padding-right: 20%;
    @media screen and (max-width: 959px) {
      padding-right: 0%;
      padding-bottom: 10%;

    }
    h2{
      font-weight: 400;
      font-size: 23px;   
      text-align: center;  
    }

    .TAServiceInfoCard{
      border: solid #313131;
      border-radius: 18px;
      margin: 0 auto;
      width: 345px;
      height: 120px;
      overflow: hidden;

      p {
        
        width: 100%;
        margin: 20px 0px 0px 27px;
      }
    }

  }
  .TAcalendarDisplay{

    grid-column: 2/3;
    width: 380px;
    display: flex;
    flex-direction: column;

  
    .TATimeDisplay{
      border-top: solid #e8e8e8;

      .TAselectedDate{
        margin: 14px 0px 0px 35px;
        font-size: 23px;
        font-weight: 400;
      }

      .spotsDisplay{

        // currectly no available spot
        p{
          font-size: large;
          margin: 15%;
        }

        // spots
        button{
          background-color: #D05028;
          color: white;
          border-radius: 10px;
          width: 99px;
          height: 37px;
          border: none;
          margin: 20px 12px 5px 12px;
        }
        .selectedButton{
          background-color: skyblue;
          color: white;
          border-radius: 10px;
          width: 99px;
          height: 37px;
          border: none;
          margin: 5px 12px 5px 12px;
        }
      }

    
    }
    .disabledMessage{
      background-color: #D05028;
      color: white;
      text-align: center;
      padding: 5px;
      border-radius: 10px;
    }
    // process button
    button{
      margin-top: 20%;
      margin-left: auto;
      background-color: #D05028;
      color: white;
      border-radius: 10px;
      width: 200px;
      height: 37px;
      border: none;

      &:disabled{
        background-color: gray;
      }
    }
  }
}
