:root {
  --border-color: #d4d4d4;
}

#availabilityContainer {
  width: 900px;
  display: grid;
  grid-template-columns: 500px 1fr;
  height: 100%;

}

#avLeft {
  height: 100%;
  border-right: 1px solid var(--border-color);
}

#avRight {
  padding: 10px 60px;
}

.avTitle {
  padding: 10px 0px 10px 40px;
  border-bottom: 1px solid var(--border-color);
}
.avClosed {
  padding: 10px;
}   
.avDaysOption {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.avDaysOption input {
  width: auto;
}
.avDaysOption label {
  display: flex;
  gap: 5px;
}
.avDaysOption span {

  font-size: 12px;
}
.calendarSmall .days {
  text-align: center;
}
.calendarSmall .month {
  text-align: center;
}
.avTime {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: space-between;
}
.avTime div {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}
.avServices input[type=checkbox] {
  width: auto;
}
.avTime input[type=time] {
  width: 150px;
}
.avTime label {
  display: flex;
  flex-direction: column;
}

.avTime span {
  font-size: 22px;
  text-decoration: underline;
}
.avServices {
  margin-top: 50px;
}
.avServices label {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}
.avServices label > div {
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  gap: 20px;
  padding: 10px;
  width: 300px;
  margin-top: 20px;
}
.avServices label  p {
  color: #ccc;
  font-size: 12px;
}
.avTime, .avServices {
  margin-left: 40px;
}

.avStartEnd select{
  padding: 5px 10px;
  border-radius: 5px;
}
#avRecurrent {
  display: flex;
  margin-top: 40px;
  gap: 20px;
  margin-left: 40px;
  
}
#avRecurrent > input[type="submit"]{
  background-color: #D05028;
  color: white;
}

#avRecurrent > input[type="submit"]:disabled{
  background-color: gray;
}
.servicesTitle p {
  margin-left: 40px;
  margin-top: 40px;
}
#recurringLabel {
  flex-direction: row;
  justify-content: flex-start;
  width: 50px;
  gap: 20px;
  margin-top: 40px;
}

.spotView{
  width: 30vw;
  overflow-x: scroll;
  margin: 70px auto;

}

.spotView > table {
  border-collapse:collapse;
}

.spotView > table > tbody> tr> th {
  border:1px solid #E8E8E8;
}

.spotView > table > tbody> tr> td {
  border:1px solid #E8E8E8;
}