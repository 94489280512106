@import "../../style/variable";

/* Navbar */
.navContainer {
  // display: grid;
  // grid-template-columns: 0.5fr 1fr 1fr 3fr 1fr;
  display: flex; // changed to flexbox to make it more responsive and vertically aligned
  justify-content: space-between;
  align-items: center; // added to align items vertically
  background-color: $contentColor;
  position: sticky;
  z-index: 10;
  padding: 7px 15px;
  flex-wrap: wrap;
  border-bottom: $borderColor solid;
  

  // logo image
  .logoContainer {
    // grid-column: 1/3;
    // grid-row: 1/2;
    padding: 0 1rem 0 1rem;
    
    .logoImg {
      height: 1.5rem;
    }
  }
  // end of logo

  // navlink container
  .navlinkContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .linkContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 25px;
      width: 100%;

      li {
        list-style: none;
        font-size: 1.2rem;
        font-family: "PT Sans", sans-serif;
        cursor: pointer;

        .createStore,
        .SignIn,
        .navbarMessages {
          color: black;
          text-decoration: none;
          font-size: 1.2rem;
          transition: transform 0.4s ease;
          border: none;
          cursor: pointer;
          white-space: nowrap;
        }

        .createStore:hover {
          border-bottom: 3px solid #cd4f28;
        }

        button {
          border: none;
          background: none;
        }

        
        .customerNavIcon {
          font-size: 1.5rem;
          color: $mainColor;
          cursor: pointer;
          position: relative;
          display: inline-block;
        }

        .businessNavIcon{
          font-size: 1.5rem;
          color: $businessMainColor;
          cursor: pointer;
          position: relative;
          display: inline-block;

        }
      }

      /* User drop down */
      .dropdownBtn {
        position: relative;
        display: inline-block;

        .dropdownMenu {
          display: none;
          border-radius: 8px;
          position: absolute;
          box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
          background-color: #fff;
          padding: 1rem;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
          z-index: 20;

          .dropdownMenuItems {
            color: black;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            padding: 0;
          }
          .dropdownMenuItems:hover {
            color: $mainColor;
            .dropdownMenu {
              display: block;
              opacity: 1;
              visibility: visible;
              transform: translate(-50px, 0);
            }
          }
        }
      }

      .dropdownBtn:hover {
        .dropdownMenu {
          display: block;
          opacity: 1;
          visibility: visible;
          transform: translate(-50px, 0);
        }
      }
      /* End of user dropdown */
    }
  }
  // end of link container

  // hamburger
  .hamburgerContainer {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  // end of hamburger

  // searchbar
  .searchbarContainer {
    display: flex;
    align-items: center;
    max-width: 70%;
    min-width: 50%;
    @media screen and (max-width: 480px) {
      max-width: none;
      width: calc(100vw - 10px);
  
      
    }
  
    .headerSearchContainer {
      display: flex;
      width: 100%;
      height: 80%;
      align-items: center;
      border: $borderColor solid;
      border-radius: 40px;

      input[type="text"] {
        height: 100%;
        padding: 10px 15px;
        width: 100%;
        outline: none;
        background-color: $contentColor;
        border: none;
        border-radius: 40px;
      }

      button[type="submit"] {
        height: 38.5px;
        padding: 10px 15px;
        background-color: $contentColor;
        border: none;
        border-radius: 40px;
        cursor: pointer;
      }
    }
  }
  // end of searchbar
}

@media (max-width: 480px) {
  .navContainer {
    .logoContainer {
      width: 50%; // Set width to 50% so it stays on the first row
    }

    .navlinkContainer {
      width: 50%; // set the width to 50% so it stays on the first row
      order: 1; // keep navcontainer in the same position
    }
    
    .searchbarContainer {
      order: 2; // move searchbarcontainer below logo container and navlinkcontainer
      padding: 0;
    }

  }
}



// Footer

.footerContainer {
  z-index: 100;

  .footerContainerWrap {
    background-color: $backgroundGrayColor;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;

      .footerHeading {
        display: flex;
        gap: 15px;
        
        @media screen and (max-width: 480px) {
          flex-direction: column;
          align-items: center;
          gap: 10px;
        }

        h2 {
          font-size: 1.5rem;
          margin: 0;

          @media screen and (max-width: 480px) {
            font-size: 1.3rem;
          }
        }
      }

      @media screen and (max-width: 480px) {
        flex-direction: column;
        gap: 10px;
      }
    }

    .socialMediaWrap {

      .socialIcons {
        display: flex;
        gap: 15px;

        .socialIconLink {
          color: $neutralColor;
          font-size: 24px;

          @media screen and (max-width: 900px) {
            font-size: 1rem;
          }
        }
      }
    }

    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .footerRights {
    background: $contentColor;
    border-top: $borderColor solid;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    padding: 15px;

    @media screen and (max-width: 480px) {
      flex-direction: column;

      p, a {
        font-size: 0.8rem;
      }
    }

    .websiteRights {
      display: flex;
      align-items: center;
      padding: 15px;

      p {
        color: $letterColorDark;
      }
    }
  }

  .footerLink {
    text-decoration: none;
    font-size: 0.9rem;
    color: $letterColorDark;
    font-weight: lighter;
    cursor: pointer;
    padding: 0 15px;

    @media screen and (max-width: 480px) {
      font-size: 0.8rem;
    }
  }
