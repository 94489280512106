// Color variables

//Customer Side
$mainColor: #e5704c;
$secondColor:#B64027;
$subColor: salmon;
$lightColor: rgba(229, 112, 76, 0.10);
$mediumLightColor: rgba(229, 112, 76, 0.15);

//Business Side
$businessMainColor: #383966;
$businessSubColor: #7F7CBE;
$businessThirdColor: #888AD51A;
$businessThirdColorSolid: #888AD5;

//Neutral Letter Color
$letterColor: white;
$letterColorDark: black;
$neutralColor: #979797;
$hoverColorWhite: rgba($color: #000000, $alpha: 0.1);

// Border color
$borderLightGray: #E7E4E2;

// Content Color
$contentColor: white;
$borderColor: #E8E8E8;
$backgroundColor: #fafafa;
$backgroundColor2: #F8F8F8;
$backgroundColor3: rgba(229,112,76, 0.1);
$grayColor: #2A393E;
$backgroundGrayColor: #F3F0ED;
$whiteHoverColor: #DAD8D6;

// Size variables

$normalFontSize: 16px;
$appealFontSize: 24px;
$titleFontSize: 36px;

// content Size
$maxWidth: 2000px;

@mixin mainContainerScale(){
  width: 100vw;
  min-height: 90vh
}

@mixin universalButton($backColor){
  height: 35px;
  width: 90px;
  margin: 15px;
  padding: 3px 5px 3px 5px;
  border: none;
  border-radius: 7px;
  color: white;
  background-color: $backColor;
  &:hover{
    cursor: pointer;
  }
}


@mixin innerContainer($verticalMargin) {
  width: 95%;
  margin: $verticalMargin auto;
}

@mixin accountDetailsContainer {
  width: 1000px;
  background-color: $contentColor;
  border-radius: 30px;
  margin: 50px auto;
}
