@import '../../../../style/variable';


// My Subscriptions Page

.noPendingContainer{
    background-color: $backgroundColor;
    height:30vh;
    display: flex;
    justify-content: center;
    
    h2 {
      color: $subColor;
      text-align: center;
      line-height: 10vh;
    }
  }
  
  .mySubscriptionsContainer{
    min-height: 90vh;

    .typeSelector{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 20px 0;

      p{
        font-size: $appealFontSize;
        margin: 0 20px;
        padding: 5px 8px;

        &:hover{
          cursor: pointer;
        }
      }
    }
    .pendingContainer{
      display: grid;
      grid-template-columns: 1fr 25%;
      
      .pendingItemContainer{
        background-color: $backgroundColor;
        grid-column: 1/2;
      }
      .checkoutContainer{
        background-color: $contentColor;
        grid-column: 2/3;
        height: fit-content;
        @include innerContainer(10px);
      }
    }
    .subscribedContainer{
      margin-top: 30px;
  
  
  
      .sortBar {
        width: 100%;
        height: 20%;
        background-color: $backgroundColor;
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        display: grid;
        grid-template-columns: 30% 70%;
  
        margin-bottom: 20px;
        .searchbarSide {
          grid-column: 1/2;
          
        }
        .radioBtnSideContainer {
          grid-column: 2/3;
          width: 70%;
          height: 100%;
          position: relative;
          text-align: center;
          p {
            height: 100%;
            display: flex;
            justify-content: space-around;
            
            
          }
        }
      }
  
      .subscribedInfo{
        position: relative;
        width: 100%;
        display: grid;
        grid-auto-columns: 50% 50%;
  
        .subscribedItemsDisplayContainer{
          width: 100%;
          display: flex;
          flex-direction: column;
          // flex-wrap: wrap;
          // align-items: flex-start;
          justify-content: center;
          gap: 20px;
          margin-bottom: 20px;
        }
  
        .subscribedInfoAnalysis{
  
        }
  
      }
    }
  
  }
  
  .checkoutContentContainer {
    padding: 10px 20px;
  }
  
  .pmtSuccessModalBackground {
    width: 100%;
    height: 100vh;
    top: 0;
    background: rgba(0,0,0, 0.6);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .pmtSuccessModalWrapper {
      border-radius: 15px;
      background: #fff;
  
      .pmtSuccessModalContent {
        padding: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 1.8;
        color: #fa8072;
        font-size: 1.6rem;
        text-align: center;
      }
  
      .pmtSuccessModalIcon {
        font-size: 3rem;
      }
    }
  }
  
  // cover letter

  .coverLetterContainer {
    padding: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  // MySubscription

  .mySubscriptionsContainer{
    width: 100%;

    .MSsubscriptionContainer{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .subCard{
        width :60%;
        display: grid;
        grid-template-columns: 2fr 3fr;
        margin: 10px auto;
        padding-bottom: 10px;

        @media screen and (max-width: 959px){
          display: flex;
          flex-direction: column;
        }

        .itemCardSide{
          grid-column: 1/2;
          min-width: 290px;
          max-width: 320px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 959px){
            margin: 0 auto;
          }
          
        }
        .itemInfo{
          grid-column: 2/3;
          display: flex;
          flex-direction: column;

          @media screen and (max-width: 959px){
            padding-bottom: 10%;
            border-bottom: $borderColor 3px solid;
          }

          .IItopInfo{
            border-bottom: $borderColor solid;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            .data {
             font-size: 20px;
             padding: 20px
            }
            
          }
          .IIbottomInfo{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

              .dataLabel {
                color: #979797;
                text-align: left;
                padding: 8px 15px 8px 15px;
               
              }
              .data {
                font-size: 20px;
                text-align: left;
                padding: 8px 25px 8px 25px;
               }
             
            

            

            
          }
          
        }
      }
    }
  }

  .buttons{
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: auto;
    
    button {
      height: 35px;
      width: 90px;
      margin: 15px;
      padding: 3px 5px 3px 5px;
      border: none;
      border-radius: 7px;
      color: white;
      &:hover{
        cursor: pointer;
      }
    }
  }

  

  .confirmContainer{

    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 11;
    top: 50%;
    left: 50%;
    background-color: $contentColor;
    transform: translate(-50%, -50%);
    padding: 30px 50px ;
    border-radius: 20px;

    p{
      :first-child{

      }
      :last-child{
        font-size: $subTitleFontSize;
      }
    }
  }

  .mySubscriptionSkelton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }