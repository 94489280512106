@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');// Outfit Full Range, Logo is Outfit Bold

// Logo Styling
$logoFont: "Outfit", serif;
@mixin LogoFontFamily() {
  font-family: "Outfit", serif; 
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

@mixin PrimaryFontFamily() {
    font-family: $logoFont;
    font-optical-sizing: auto;
    font-style: normal;
}

@mixin TitleFont() {
  font-family: $logoFont;
  font-weight: 700;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 24px;
  color: black;
}

@mixin SubtitleFont() {
  font-family: $logoFont;
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 24px;
  color: black;
}

@mixin ArticleFont() {
  font-family: $logoFont;
  font-weight: 300;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 12px;
  color: black;
}


// root font size is 16px
$normalFontSize: 1rem; // 16px
$appealFontSize: 1.5rem; //24px
$titleFontSize: 2rem; //32px

$maxWidth: 2000px;

.capitalize {
  text-transform: capitalize;
}