.calendarContainer {
  display: grid;
  grid-template-columns: 30% 70%;
  width: 1000px;
  height: 600px;
  text-align: center;
  padding: 20px;
  margin-right: 20px;
}
.calendarContainer .calright {
  height: 500px;
  overflow-y: scroll;
}

.calendarSmall {
  color: #8D8D8D;
}
.calendarSmall .month {
  display: grid;
  grid-template-columns: 20% 1fr 20%;
  width: 100%;
  height: 40px;
}
.calendarSmall .daysWeek {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  color: #8D8D8D;
  border-top: 1px solid #d4d4d4;
  padding-top: 10px;
  font-size: 11px;
}
.calendarSmall .days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.calendarSmall .days div:hover {
  background-color: rgb(255, 207, 172);
  cursor: pointer;
}
.calendarSmall .calendarArrow {
  color: #8D8D8D;
  cursor: pointer;
}

.BigCalendar {
  width: 100%;
  height: 100%;
}
.BigCalendar .modalContainer {
  width: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  box-shadow: 3px 3px 2px 0px rgba(0, 0, 0, 0.29);
}
.BigCalendar .modalContainer .modalClose:hover {
  cursor: pointer;
}
.BigCalendar .calendarDateHeader {
  color: #8D8D8D;
  font-size: 16px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: 40px;
}
.BigCalendar .calendarHeader {
  display: grid;
  grid-template-columns: 40px repeat(7, 1fr);
  grid-column: 1/9;
  border-top: 1px solid #d4d4d4;
  color: #A6A6A6;
  position: sticky;
  top: 0px;
  height: 40px;
  background-color: #FAFAFA;
  font-size: 12px;
}
.BigCalendar .calendarHeader div {
  border-right: 1px solid #E8E8E8;
  height: 100%;
}
.BigCalendar .timeCalendar {
  grid-column: 1/9;
  font-size: 10px;
  display: grid;
  grid-template-columns: 40px repeat(7, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  color: #979797;
}
.BigCalendar .timeCalendar .time {
  border-right: 1px solid #E8E8E8;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.BigCalendar .timeField {
  background-color: #FAFAFA;
  height: 30px;
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  color: #313131;
}
.BigCalendar .timeField:hover {
  cursor: pointer;
}
.BigCalendar .schedule {
  border-left: 4px solid rgb(252, 101, 74);
  border-bottom: none !important;
  border-right: none !important;
  background-color: rgb(253, 241, 233) !important;
  border-radius: 2px;
  font-size: 12px;
  padding-left: 10px;
  margin-top: 5px;
  cursor: pointer;
}
.BigCalendar .apptWeekViewContainer {
  width: 652px;
}
.BigCalendar .bigMonthView .schedule {
  cursor: pointer;
}/*# sourceMappingURL=Calendar.css.map */